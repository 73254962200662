import MainLayoutVue from '@/generic/layouts/MainLayout.vue';
import SurveyFinishVue from './views/SurveyFinish.vue';
import SurveyViewVue from './views/SurveyView.vue';
import SurveyClosedVue from './views/SurveyClosed.vue';
import SurveyViewCombinedVue from './views/SurveyViewCombined.vue';
import SurveyStartVue from './views/SurveyStart.vue';

export default [
    {
        path: '/survey',
        component: MainLayoutVue,
        children: [
            {
                path: ':arg?',
                component: SurveyViewVue,
                name: 'survey-view',
            },
            {
                path: 'combined',
                name: 'survey-combined',
                component: SurveyViewCombinedVue,
            },
            {
                path: 'start',
                name: 'survey-start',
                component: SurveyStartVue,
            },
            {
                path: 'finish',
                name: 'survey-finish',
                component: SurveyFinishVue,
            },
            {
                path: 'closed',
                name: 'survey-closed',
                component: SurveyClosedVue,
            },
        ],
    },
];
