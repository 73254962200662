import BarChartScore from '@/report/models/Charts/BarChartScore';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import he from 'he';
import { PillarThresholdType } from '@/report/models/PillarThreshold';

export default defineComponent({
    props: {
        primary: { type: Boolean, default: false },
        scores: { type: Object as PropType<BarChartScore> },
        stacked: { type: Boolean, default: false },
        offset: { type: Number },
        label: { type: String },
        color: { type: String },
        scoreScale: { type: Number, default: 100 },
        category: { type: String },
    },
    computed: {
        colorClass() {
            if (this.color) {
                return this.color;
            }

            if (this.primary) {
                return 'primary';
            }
            return 'secondary';
        },
    },
    setup(props) {
        const store = useStore();
        const showPrimary = computed(() => store.getters['report/showPrimary']);
        const secondary = computed(() => !props.primary);

        const show = computed(() => {
            if (!props.scores || typeof props.scores.teamMedian === 'undefined') {
                return false;
            }

            return showPrimary.value || (!showPrimary.value && !props.primary);
        });

        const decodedLabel = computed(() => he.decode(props.label));

        const direction = ref('');
        const { t } = useI18n();
        const toolTipGroupMedianTranslation = computed(() => t('generic.TOOLTIP_GROUP_MEDIAN'));
        const toolTipPositiveDeviationTranslation = computed(() => t('generic.TOOLTIP_POSITIVE_DEVIATION'));
        const toolTipNegativeDeviationTranslation = computed(() => t('generic.TOOLTIP_NEGATIVE_DEVIATION'));
        const tooltipYourScore = computed(() => t('generic.TOOLTIP_YOUR_SCORE'));
        const tooltipMedianValue = computed(() => parseFloat((props.scores.tooltipScore ? props.scores.tooltipScore : (props.scores.teamMedian / 100 * props.scoreScale)).toFixed(2)));
        const barChartRatio = computed(() => store.getters.pillarThreshold(PillarThresholdType.barChartRatio, props.category));

        const normalizeValue = (value: number) => {
            const min = barChartRatio.value[0] ?? 0;
            const max = barChartRatio.value[1] ?? 100;
            const newMin = 0;
            const newMax = 100;
            return ((value - min) / (max - min)) * (newMax - newMin) + newMin;
        };

        const combinedScores = computed(() => {
            const combined = {};
            if (props.scores && props.scores.participants) {
                props.scores.participants.forEach((score) => {
                    const name = `${score.firstName} ${score.lastName}`;
                    if (!combined[score.score]) {
                        combined[score.score] = [name];
                    } else {
                        combined[score.score].push(name);
                    }
                });
            }
            return combined;
        });

        const scoreToolTip = (score) => {
            return `
                ${combinedScores.value[score].join('<br/>')}<br/>
                <b>${score}</b>
            `;
        };

        const medianPosition = computed(() => `${direction.value === 'ltr' ? 'left' : 'right'}: ${normalizeValue(props.scores.teamMedian)}%`);
        const positivePosition = computed(() => {
            const maxPoint = normalizeValue(props.scores.teamMedian + props.scores.positiveAverage);
            const median = normalizeValue(props.scores.teamMedian);
            const width = maxPoint - median;
            return `width: ${width}%; ${direction.value === 'ltr' ? `left: ${median}%` : `right: ${median}%`}`;
        });
        const negativePosition = computed(() => {
            const minPoint = normalizeValue(props.scores.teamMedian - props.scores.negativeAverage);
            const median = normalizeValue(props.scores.teamMedian);
            const width = median - minPoint;
            return `width: ${width}%; ${direction.value === 'ltr' ? `right: ${100 - median}%` : `left: ${100 - median}%`}`;
        });
        const labelPosition = computed(
            () => `${direction.value === 'ltr' ? 'left' : 'right'}: ${normalizeValue(props.scores.teamMedian) - normalizeValue(props.scores.negativeAverage)}`,
        );
        const personalPosition = computed(() => `${direction.value === 'ltr' ? 'left' : 'right'}: ${normalizeValue(props.scores.personalScore)}%`);
        const teamPosition = computed(() => `${direction.value === 'ltr' ? 'left' : 'right'}`);

        onMounted(() => {
            direction.value = document.querySelector('body').getAttribute('direction');
        });

        return {
            show,
            secondary,
            combinedScores,
            scoreToolTip,
            toolTipGroupMedianTranslation,
            toolTipPositiveDeviationTranslation,
            toolTipNegativeDeviationTranslation,
            tooltipYourScore,
            medianPosition,
            positivePosition,
            negativePosition,
            labelPosition,
            personalPosition,
            teamPosition,
            decodedLabel,
            tooltipMedianValue,
        };
    },
});
