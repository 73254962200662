import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Answer from '../models/Answer';
import { FinishSurveyStatus } from '../models/FinishSurveyStatus';
import Question from '../models/Question';
import Survey from '../models/Survey';
import SurveyListResponse, { DemoGraphicSurveyQuestion } from '../models/SurveyListResponse';

export const setup = () => {
    const store = useStore();
    const router = useRouter();
    const finishingSurvey = ref(false);

    const finishSurvey = async (surveyId: number): Promise<string> => {
        if (finishingSurvey.value) {
            return;
        }

        finishingSurvey.value = true;
        const status: FinishSurveyStatus = await store.dispatch('finishSurvey', { surveyId });
        finishingSurvey.value = false;

        if (status === FinishSurveyStatus.NotVerified || status === FinishSurveyStatus.NotCompleted) {
            return 'Survey could not be completed. Please refresh the browser and try again.';
        }
        return '';
    };

    const redirectToFinishPage = () => {
        router.push({ name: 'survey-finish' });
    };

    const redirectToClosedPage = () => {
        router.push({ name: 'survey-closed' });
    };

    return { finishSurvey, finishingSurvey, redirectToFinishPage, redirectToClosedPage, loadDemographicSurvey };
};

export const loadDemographicSurvey = (surveyList: SurveyListResponse): Survey => {
    return new Survey({
        instrumentName: 'Demographic data',
        instrumentAlias: 'demographic_data',
        demographicsEnabled: true,
        sectionTitleKey: 'generic.DEMOGRAPHICS_SECTION_TITLE',
        sectionSubTitleKey: 'generic.DEMOGRAPHICS_SECTION_DESCRIPTION',
        surveyPriority: surveyList.demographicSurvey.surveyPriority,
        questions: surveyList.demographicSurvey.questions
            .map((q: DemoGraphicSurveyQuestion) => {
                return new Question({
                    id: q.id,
                    answerOptions: q.answerOptions.map((o) => {
                        return new Answer({
                            alias: o.label,
                            label: q.label,
                            checked: q.value === o.label,
                        });
                    }),
                    isDemographic: true,
                    priority: surveyList.demographicSurvey.surveyPriority,
                    question: q.question,
                    questionType: q.questionType,
                    label: q.label,
                    answer: { value: q.value },
                    order: q.order,
                    value: q.value,
                    view: q.label.includes('default') ? q.label : q.view,
                });
            })
            .sort((a, b) => a.order - b.order),
    });
};
